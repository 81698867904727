import { fetchGQL } from "app/api"
import {
  SupplierCollectionFilterInput,
  SupplierCollectionsQuery,
  SupplierCollectionsQueryVariables,
  UserSupplierCollectionFilterInput,
  UserSupplierCollectionsQuery,
  UserSupplierCollectionsQueryVariables,
  SupplierCollectionClaimInput,
  SupplierCollectionClaimMutation,
  SupplierCollectionClaimMutationVariables,
  SupplierCollectionReleaseInput,
  SupplierCollectionReleaseMutation,
  SupplierCollectionReleaseMutationVariables,
  SupplierCollectionUpdateInput,
  SupplierCollectionUpdateMutation,
  SupplierCollectionUpdateMutationVariables,
} from "generated/graphql"

import gql from "graphql-tag"

const SUPPLIER_COLLECTIONS = gql`
  query supplierCollections($filter: SupplierCollectionFilterInput!) {
    supplierCollections(filter: $filter) {
      supplierName
      supplierId
      round
      address {
        street
        postCode
        city
      }
      count
      pickupTime
      pickupDate
      container {
        id
        supplierId
        name
        owed
      }
      region
      driverName
      isCollected
      collectedAt
      note
    }
  }
`
export const getSupplierCollections = async (
  input: SupplierCollectionFilterInput
): Promise<SupplierCollectionsQuery | undefined> => {
  const variables: SupplierCollectionsQueryVariables = { filter: input }

  const data = await fetchGQL<
    SupplierCollectionsQuery,
    SupplierCollectionsQueryVariables
  >(SUPPLIER_COLLECTIONS, variables)
  return data
}

const USERS_SUPPLIER_COLLECTIONS = gql`
  query userSupplierCollections($filter: UserSupplierCollectionFilterInput!) {
    userSupplierCollections(filter: $filter) {
      supplierName
      supplierId
      round
      address {
        street
        postCode
        city
      }
      count
      pickupTime
      pickupDate
      container {
        id
        supplierId
        name
        owed
      }
      region
      driverName
      isCollected
      collectedAt
      note
    }
  }
`
export const getUserSupplierCollections = async (
  input: UserSupplierCollectionFilterInput
): Promise<UserSupplierCollectionsQuery | undefined> => {
  const variables: UserSupplierCollectionsQueryVariables = {
    filter: { ...input },
  }

  const data = await fetchGQL<
    UserSupplierCollectionsQuery,
    UserSupplierCollectionsQueryVariables
  >(USERS_SUPPLIER_COLLECTIONS, variables)
  return data
}

const SUPPLIER_COLLECTION_CLAIM = gql`
  mutation supplierCollectionClaim($in: SupplierCollectionClaimInput!) {
    supplierCollectionClaim(in: $in)
  }
`

export const supplierCollectionClaim = async (
  input: SupplierCollectionClaimInput
): Promise<SupplierCollectionClaimMutation | undefined> => {
  const variables: SupplierCollectionClaimMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<
    SupplierCollectionClaimMutation,
    SupplierCollectionClaimMutationVariables
  >(SUPPLIER_COLLECTION_CLAIM, variables)
  return data
}

const SUPPLIER_COLLECTION_RELEASE = gql`
  mutation supplierCollectionRelease($in: SupplierCollectionReleaseInput) {
    supplierCollectionRelease(in: $in)
  }
`

export const supplierCollectionRelease = async (
  input: SupplierCollectionReleaseInput
): Promise<SupplierCollectionReleaseMutation | undefined> => {
  const variables: SupplierCollectionReleaseMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<
    SupplierCollectionReleaseMutation,
    SupplierCollectionReleaseMutationVariables
  >(SUPPLIER_COLLECTION_RELEASE, variables)
  return data
}

const SUPPLIER_COLLECTION_UPDATE = gql`
  mutation supplierCollectionUpdate($in: SupplierCollectionUpdateInput) {
    supplierCollectionUpdate(in: $in)
  }
`

export const supplierCollectionUpdate = async (
  input: SupplierCollectionUpdateInput
): Promise<SupplierCollectionUpdateMutation | undefined> => {
  const variables: SupplierCollectionUpdateMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<
    SupplierCollectionUpdateMutation,
    SupplierCollectionUpdateMutationVariables
  >(SUPPLIER_COLLECTION_UPDATE, variables)
  return data
}
