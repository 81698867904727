import { fetchGQL } from "app/api"
import {
  ArticleSupplierInfoQuery,
  ArticleSupplierInfoQueryVariables,
} from "generated/graphql"

import gql from "graphql-tag"

const ARTICLE_SUPPLIER_INFO = gql`
  query articleSupplierInfo($round: Int!, $supplierID: Int!) {
    articleSupplierInfo(round: $round, supplierID: $supplierID) {
      id
      orderID
      ewID
      supplierID
      supplierName
      bdb
      quantity
      botname
      sorte
      size
    }
  }
`
export const getArticleSupplierInfo = async (
  round: number,
  supplierID: number
): Promise<ArticleSupplierInfoQuery | undefined> => {
  const variables: ArticleSupplierInfoQueryVariables = { round, supplierID }

  const data = await fetchGQL<
    ArticleSupplierInfoQuery,
    ArticleSupplierInfoQueryVariables
  >(ARTICLE_SUPPLIER_INFO, variables)
  return data
}
