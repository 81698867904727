import { ArrowBack } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { CollapsibleCard } from "component/CollapsibleCard/CollapsibleCard"
import {
  SupplierCollection,
  SupplierContainer,
  SupplierOrderItem,
} from "generated/graphql"
import { getArticleSupplierInfo } from "queries/articleSupplierInfo"
import { getSupplierContainerList } from "queries/getSuppliersContaerList"
import {
  getSupplierCollections,
  supplierCollectionUpdate,
} from "queries/supplierCollections"
import { supplierContainersUpsert } from "queries/supplierContainersUpsert"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { dataGridDEde } from "utils/dataGridLocale"
import { useLocalStorage } from "utils/useLocalStorage"

// Typ für Autocomplete-Optionen erweitern
type ContainerWithOption = SupplierContainer & { inputValue?: string }

const colsArticles: GridColDef[] = [
  {
    field: "botname",
    headerName: "Ware",
    width: 300,
    renderCell: (params) => {
      return (
        <Box>
          <Typography variant="body2" component="div">
            {params.row.botname}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            {params.row.sorte}
          </Typography>
        </Box>
      )
    },
  },
  { field: "size", headerName: "Größe", width: 100 },
  { field: "quantity", headerName: "Anzahl", width: 200 },
]

const colsContainer: GridColDef[] = [
  { field: "name", headerName: "Container", width: 150 },
  { field: "owed", headerName: "Saldo", width: 100, type: "number" },
]

const TourStopPage = () => {
  const [tour, setTour] = useLocalStorage<SupplierCollection[]>("tour", [])
  const { supplierID, round } = useParams()
  const [openContainerDialog, setOpenContainerDialog] = useState(false)
  const [onlyPart, setOnlyPart] = useState(false)
  const [note, setNote] = useState("")
  const [collection, setCollection] = useState<SupplierCollection>()
  const [rowsArticles, setRowsArticles] = useState<SupplierOrderItem[]>([])
  const [rowsContainers, setRowsContainers] = useState<SupplierContainer[]>([])
  const [containerList, setContainerList] = useState<SupplierContainer[]>([])
  const [containerData, setContainerData] = useState<{ [key: string]: number }>(
    {}
  )
  const [customContainer, setCustomContainer] =
    useState<SupplierContainer | null>(null)
  const [isCollectionReady, setIsCollectionReady] = useState(false)
  const filter = createFilterOptions<SupplierContainer>()

  const getCollections = async () => {
    const collections = await getSupplierCollections({ activeOnly: true })
    if (collections && supplierID && round) {
      const newRow = collections.supplierCollections.find(
        (item) =>
          !item.isCollected &&
          item.supplierId === parseInt(supplierID) &&
          item.round === parseInt(round)
      )
      if (newRow) {
        setCollection(newRow)
        setRowsContainers(
          newRow.container.filter(
            (container): container is SupplierContainer => container !== null
          )
        )
      }
    }
  }

  useEffect(() => {
    getCollections()
  }, [supplierID, round])

  const getArticles = async () => {
    if (collection && supplierID) {
      const articles = await getArticleSupplierInfo(
        collection.round,
        parseInt(supplierID)
      )
      if (articles) {
        const combinedArticles = articles.articleSupplierInfo.reduce(
          (acc, article) => {
            const existingArticle = acc.find((a) => a.bdb === article.bdb)
            if (existingArticle) {
              existingArticle.quantity += article.quantity
            } else {
              acc.push({ ...article })
            }
            return acc
          },
          [] as SupplierOrderItem[]
        )

        setRowsArticles(combinedArticles)
      }
    }
  }

  useEffect(() => {
    getArticles()
  }, [collection, supplierID])

  const getContainerList = async () => {
    if (supplierID) {
      const container = await getSupplierContainerList()
      if (container) {
        setContainerList(container.getSupplierContainerList)
      }
    }
  }

  useEffect(() => {
    getContainerList()
  }, [collection, supplierID])

  const closeContainerDialog = () => {
    setOpenContainerDialog(false)
    setCustomContainer(null)
    setContainerData({})
    setIsCollectionReady(false)
    setOnlyPart(false)
    setNote("")
  }

  const handleContainerReadyClick = async () => {
    const updatedContainers = rowsContainers.map((container) => {
      const received = containerData[`received_${container.name}`] || 0
      const given = containerData[`given_${container.name}`] || 0

      return {
        ...container,
        owed: container.owed - received + given,
      }
    })

    if (
      customContainer &&
      containerData[`received_${customContainer}`] &&
      supplierID
    ) {
      updatedContainers.push({
        name: customContainer.name,
        owed: -containerData[`received_${customContainer}`],
        id: customContainer.id,
        supplierId: parseInt(supplierID),
      })
    }
    setRowsContainers(updatedContainers)

    if (updatedContainers.length > 0 && supplierID) {
      await supplierContainersUpsert({
        supplierId: parseInt(supplierID),
        container: updatedContainers,
      })
    }

    if (isCollectionReady && supplierID && collection) {
      await supplierCollectionUpdate({
        supplierId: parseInt(supplierID),
        runde: collection.round,
        completed: true,
      })
      closeContainerDialog()
      handleReturn()
      const newTour = tour.filter(
        (row) => row.supplierId !== parseInt(supplierID)
      )
      setTour(newTour)
    }

    if (onlyPart && supplierID && collection) {
      await supplierCollectionUpdate({
        supplierId: parseInt(supplierID),
        runde: collection.round,
        completed: false,
        note: note,
      })
      console.log("Bemerkung", note)
    }

    closeContainerDialog()
  }

  const handleReturn = () => {
    window.history.back()
  }

  const handleRoutePlanning = () => {
    if (collection) {
      let url = "https://www.google.com/maps/place/"
      url += `${collection.address.street.replaceAll(" ", "+")}+${
        collection.address.postCode
      }+${collection.address.city.replaceAll(" ", "+")}/`
      window.open(url)
    }
  }

  const showButton = () => {
    if (supplierID) {
      const returnValue = tour.some(
        (item) => item.supplierId === parseInt(supplierID)
      )
      return returnValue
    }
    return false
  }

  return (
    <>
      {collection && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            flexGrow: 1,
            flex: 1,
            padding: 1,
            paddingTop: 2,
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IconButton onClick={handleReturn}>
              <ArrowBack sx={{ fontSize: 30 }} />
            </IconButton>
            <Typography variant="h4">{collection.supplierName}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Box>
              <Typography>{collection.address.street}</Typography>

              <Typography>
                {collection.address.postCode} {collection.address.city}
              </Typography>
              <Typography variant="caption">{collection.note}</Typography>
            </Box>
            <Box>
              {collection.address.street != "" &&
                collection.address.city != "" &&
                collection.address.postCode != "" && (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleRoutePlanning}
                  >
                    zu Google Maps
                  </Button>
                )}
            </Box>
          </Box>
          <CollapsibleCard defaultOpen={true} title="Artikel">
            <Box sx={{ height: "75vh", overflow: "auto", pb: 1 }}>
              <DataGridPro
                getRowId={(row) => row.id}
                rows={rowsArticles}
                columns={colsArticles}
                localeText={dataGridDEde}
                disableColumnMenu
                density="compact"
                getRowHeight={() => "auto"}
              />
            </Box>
          </CollapsibleCard>
          <Box sx={{ mb: "auto", pb: 1 }}>
            <CollapsibleCard defaultOpen={false} title="Container">
              <Box sx={{ height: "30vh", overflow: "auto" }}>
                <DataGridPro
                  getRowId={(row) => row.id}
                  rows={rowsContainers}
                  columns={colsContainer}
                  localeText={dataGridDEde}
                  disableColumnMenu
                  density="compact"
                />
              </Box>
              <Typography variant="caption">
                Saldo größer als Null: Der Lieferant schuldet Pflanzmich
                Container
              </Typography>
              {showButton() && (
                <Button fullWidth onClick={() => setOpenContainerDialog(true)}>
                  Lademittel tauschen
                </Button>
              )}
            </CollapsibleCard>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            {showButton() && (
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setOnlyPart(true)
                  setOpenContainerDialog(true)
                }}
              >
                Teilabschluss
              </Button>
            )}

            {showButton() && (
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setOpenContainerDialog(true)
                  setIsCollectionReady(true)
                }}
              >
                Stop abschließen
              </Button>
            )}
          </Box>

          <Dialog
            open={openContainerDialog}
            onClose={closeContainerDialog}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Lademittel dokumentieren</DialogTitle>
            <DialogContent>
              {rowsContainers.map((container) => (
                <Grid
                  key={container.id}
                  container
                  spacing={1}
                  sx={{ alignItems: "center", mt: 1 }}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>{container.name}</Typography>
                    <Typography>({container.owed})</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="erhalten"
                      fullWidth
                      type="number"
                      onChange={(e) =>
                        setContainerData({
                          ...containerData,
                          [`received_${container.name}`]: parseInt(
                            e.target.value
                          ),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="abgegeben"
                      fullWidth
                      type="number"
                      onChange={(e) =>
                        setContainerData({
                          ...containerData,
                          [`given_${container.name}`]: parseInt(e.target.value),
                        })
                      }
                    />
                  </Grid>
                </Grid>
              ))}

              <Grid container spacing={1} sx={{ alignItems: "center", mt: 1 }}>
                <Grid item xs={6}>
                  <Autocomplete
                    value={customContainer}
                    id="Container"
                    options={containerList}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option
                      }
                      if ((option as ContainerWithOption).inputValue) {
                        return (option as ContainerWithOption).inputValue!
                      }
                      return `${option.name}`
                    }}
                    onChange={(_, newValue) => {
                      if (typeof newValue === "string") {
                        if (supplierID) {
                          setCustomContainer({
                            id: 0,
                            name: newValue,
                            owed: 0,
                            supplierId: parseInt(supplierID),
                          })
                        }
                      } else if (
                        newValue &&
                        (newValue as ContainerWithOption).inputValue &&
                        supplierID
                      ) {
                        setCustomContainer({
                          name: (newValue as ContainerWithOption).inputValue!,
                          id: 0,
                          owed: 0,
                          supplierId: parseInt(supplierID),
                        })
                      } else {
                        setCustomContainer(newValue)
                        if (newValue) {
                          const newContainerList = containerList.filter(
                            (container) => container.id !== newValue.id
                          )
                          setContainerList(newContainerList)
                        }
                      }
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    renderOption={(props, option, state) => (
                      <li {...props} key={option.id}>
                        {state.inputValue ? "Hinzufügen: " : ""}
                        {option.name}
                      </li>
                    )}
                    sx={{ width: "100%" }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Container" />
                    )}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params)
                      if (params.inputValue !== "" && supplierID) {
                        filtered.push({
                          inutputValue: params.inputValue,
                          name: params.inputValue,
                          id: 0,
                          owed: 0,
                          supplierId: parseInt(supplierID),
                        } as ContainerWithOption)
                      }
                      return filtered
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="erhalten"
                    fullWidth
                    type="number"
                    onChange={(e) =>
                      setContainerData({
                        ...containerData,
                        [`received_${customContainer}`]: parseInt(
                          e.target.value
                        ),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField disabled />
                </Grid>
              </Grid>

              {onlyPart && (
                <TextField
                  label="Bemerkung"
                  fullWidth
                  multiline
                  sx={{ mt: 2 }}
                  onChange={(e) => setNote(e.target.value)}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="error"
                onClick={closeContainerDialog}
                fullWidth
              >
                Abbrechen
              </Button>
              <Button
                variant="contained"
                onClick={handleContainerReadyClick}
                fullWidth
              >
                Fertig
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  )
}

export default TourStopPage
