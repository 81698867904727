import { fetchGQL } from "app/api"
import {
  SupplierContainersUpsertInput,
  SupplierContainersUpsertMutation,
  SupplierContainersUpsertMutationVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const SUPPLIER_CONTAINER_UPSERT = gql`
  mutation supplierContainersUpsert($in: SupplierContainersUpsertInput) {
    supplierContainersUpsert(in: $in)
  }
`

export const supplierContainersUpsert = async (
  input: SupplierContainersUpsertInput
): Promise<SupplierContainersUpsertMutation | undefined> => {
  const variables: SupplierContainersUpsertMutationVariables = {
    in: input,
  }
  const data = await fetchGQL<
    SupplierContainersUpsertMutation,
    SupplierContainersUpsertMutationVariables
  >(SUPPLIER_CONTAINER_UPSERT, variables)
  return data
}
