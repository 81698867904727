import { GridLocaleText } from "@mui/x-data-grid-pro"

export const dataGridDEde: Partial<GridLocaleText> = {
  // Root
  noRowsLabel: "Keine Zeilen",
  noResultsOverlayLabel: "Keine Ergebnisse gefunden.",

  // Density selector toolbar button text
  toolbarDensity: "Dichte",
  toolbarDensityLabel: "Dichte",
  toolbarDensityCompact: "Kompakt",
  toolbarDensityStandard: "Standard",
  toolbarDensityComfortable: "Komfortabel",

  // Columns selector toolbar button text
  toolbarColumns: "Spalten",
  toolbarColumnsLabel: "Spalten auswählen",

  // Filters toolbar button text
  toolbarFilters: "Filter",
  toolbarFiltersLabel: "Filter anzeigen",
  toolbarFiltersTooltipHide: "Filter ausblenden",
  toolbarFiltersTooltipShow: "Filter anzeigen",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? count + " aktive Filter" : count + " aktiver Filter",

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "Suchen...",
  toolbarQuickFilterLabel: "Suche",
  toolbarQuickFilterDeleteIconLabel: "Löschen",

  // Export selector toolbar button text
  toolbarExport: "Exportieren",
  toolbarExportLabel: "Exportieren",
  toolbarExportCSV: "Als CSV herunterladen",
  toolbarExportPrint: "Drucken",
  toolbarExportExcel: "Als Excel herunterladen",

  // Filter panel text
  filterPanelAddFilter: "Filter hinzufügen",
  filterPanelRemoveAll: "Alle entfernen",
  filterPanelDeleteIconLabel: "Löschen",
  filterPanelLogicOperator: "Logischer Operator",
  filterPanelOperator: "Operator",
  filterPanelOperatorAnd: "Und",
  filterPanelOperatorOr: "Oder",
  filterPanelColumns: "Spalten",
  filterPanelInputLabel: "Wert",
  filterPanelInputPlaceholder: "Filterwert",

  // Filter operators text
  filterOperatorContains: "enthält",
  filterOperatorEquals: "gleich",
  filterOperatorStartsWith: "beginnt mit",
  filterOperatorEndsWith: "endet mit",
  filterOperatorIs: "ist",
  filterOperatorNot: "ist nicht",
  filterOperatorAfter: "ist nach",
  filterOperatorOnOrAfter: "ist am oder nach",
  filterOperatorBefore: "ist vor",
  filterOperatorOnOrBefore: "ist am oder vor",
  filterOperatorIsEmpty: "ist leer",
  filterOperatorIsNotEmpty: "ist nicht leer",
  filterOperatorIsAnyOf: "ist eine der",

  // Header filter operators text
  //headerFilterOperatorContains: 'Enthält',
  //headerFilterOperatorEquals: 'Gleich',
  //headerFilterOperatorStartsWith: 'Beginnt mit',
  //headerFilterOperatorEndsWith: 'Endet mit',
  //headerFilterOperatorIs: 'Ist',
  //headerFilterOperatorNot: 'Ist nicht',
  //headerFilterOperatorAfter: 'Ist nach',
  //headerFilterOperatorOnOrAfter: 'Ist am oder nach',
  //headerFilterOperatorBefore: 'Ist vor',
  //headerFilterOperatorOnOrBefore: 'Ist am oder vor',
  //headerFilterOperatorIsEmpty: 'Ist leer',
  //headerFilterOperatorIsNotEmpty: 'Ist nicht leer',
  //headerFilterOperatorIsAnyOf: 'Ist eine der',
  //

  // Filter values text
  filterValueAny: "beliebig",
  filterValueTrue: "wahr",
  filterValueFalse: "falsch",

  // Column menu text
  columnMenuLabel: "Menü",
  columnMenuShowColumns: "Spalten anzeigen",
  columnMenuManageColumns: "Spalten verwalten",
  columnMenuFilter: "Filter",
  columnMenuHideColumn: "Spalte ausblenden",
  columnMenuUnsort: "Unsortieren",
  columnMenuSortAsc: "Aufsteigend sortieren",
  columnMenuSortDesc: "Absteigend sortieren",

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? count + "aktive Filter" : count + " aktiver Filter",
  columnHeaderFiltersLabel: "Filter anzeigen",
  columnHeaderSortIconLabel: "Sortieren",

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? count.toLocaleString() + " ausgewählte Zeilen"
      : count.toLocaleString() + " ausgewählte Zeile",

  // Total row amount footer text
  footerTotalRows: "Gesamtzahl Zeilen:",

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    visibleCount.toLocaleString() + " von " + totalCount.toLocaleString(),

  // Checkbox selection text
  checkboxSelectionHeaderName: "Auswahl per Checkbox",
  checkboxSelectionSelectAllRows: "Alle Zeilen auswählen",
  checkboxSelectionUnselectAllRows: "Auswahl aller Zeilen aufheben",
  checkboxSelectionSelectRow: "Zeile auswählen",
  checkboxSelectionUnselectRow: "Auswahl der Zeile aufheben",

  // Boolean cell text
  booleanCellTrueLabel: "ja",
  booleanCellFalseLabel: "nein",

  // Actions cell more text
  actionsCellMore: "mehr",

  // Column pinning text
  pinToLeft: "Links anheften",
  pinToRight: "Rechts anheften",
  unpin: "Lösen",

  // Tree Data
  treeDataGroupingHeaderName: "Gruppe",
  treeDataExpand: "Kinder anzeigen",
  treeDataCollapse: "Kinder ausblenden",

  // Grouping columns
  groupingColumnHeaderName: "Gruppe",
  groupColumn: (name) => `Nach ${name} gruppieren`,
  unGroupColumn: (name) => `Gruppierung nach ${name} aufheben`,

  // Master/detail
  detailPanelToggle: "Detailbereich umschalten",
  expandDetailPanel: "Erweitern",
  collapseDetailPanel: "Einklappen",

  // Used core components translation keys
  MuiTablePagination: {
    labelRowsPerPage: "Zeilen pro Seite:",
    labelDisplayedRows: (info) => `${info.from}-${info.to} von ${info.count}`,
  },

  // Row reordering text
  rowReorderingHeaderName: "Zeilenreihenfolge",

  // Aggregation
  aggregationMenuItemHeader: "Aggregation",
  aggregationFunctionLabelSum: "Summe",
  aggregationFunctionLabelAvg: "Durchschnitt",
  aggregationFunctionLabelMin: "Minimum",
  aggregationFunctionLabelMax: "Maximum",
  aggregationFunctionLabelSize: "Größe",
}
