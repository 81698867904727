import { fetchGQL } from "app/api"
import {
  GetSupplierContainerListQuery,
  GetSupplierContainerListQueryVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const GET_SUPPLIER_CONTAINER_LIST = gql`
  query getSupplierContainerList {
    getSupplierContainerList {
      id
      supplierId
      name
      owed
    }
  }
`

export const getSupplierContainerList = async (): Promise<
  GetSupplierContainerListQuery | undefined
> => {
  const data = await fetchGQL<
    GetSupplierContainerListQuery,
    GetSupplierContainerListQueryVariables
  >(GET_SUPPLIER_CONTAINER_LIST)

  return data
}
