import { ExpandLess, ExpandMore } from "@mui/icons-material"
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material"
import React from "react"
import { useState } from "react"

type CollapsibleCardProps = {
  defaultOpen: boolean
  title: string
  children: React.ReactNode
}

export const CollapsibleCard = (props: CollapsibleCardProps) => {
  const { defaultOpen, title, children } = { ...props }
  const [open, setOpen] = useState(defaultOpen)

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <Collapse in={open}>
        <CardContent sx={{ m: 2 }}>{children}</CardContent>
      </Collapse>
    </Card>
  )
}
