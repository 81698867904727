import ContentCutIcon from "@mui/icons-material/ContentCut"
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { Article } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { orderItemDistribute } from "queries/orderItemDistribute"
import { orderItemReset } from "queries/orderItemReset"
import { tagItem } from "queries/tagItem"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import CollapsibleRowArticle from "./CollapsibleRowArticle"

interface CollapsibleRowProps {
  article: Article
  orderID: number
  setTagRes: (prevState: any) => void
  setCheckedItems: (state: any) => void
}

const textStart = "left"

const CollapsibleRow = (props: CollapsibleRowProps) => {
  const { article, orderID, setTagRes, setCheckedItems } = props
  const [rowIsCollapsed, setRowIsCollapsed] = useState(false)
  const { t } = useTranslation()

  const handleLabelling = async () => {
    const res = await tagItem(
      article.id.toString() + (orderID % 100).toString().padStart(2, "0")
    )
    if (res) {
      setTagRes((prevState: any) => {
        if ("scannedArticle" in prevState) {
          return {
            ...prevState,
            orderDetails: {
              ...prevState.orderDetails,
              items: res.tagItem.orderDetails
                ? [...res.tagItem.orderDetails.items]
                : [],
            },
          }
        } else {
          return { ...res.tagItem.orderDetails }
        }
      })
    }
  }

  const handleDistributed = async () => {
    const res = await orderItemDistribute({
      barcode: article.id.toString(),
      hasSuffixStep1: false,
    })
    if (res) {
      setTagRes((prevState: any) => {
        if ("scannedArticle" in prevState) {
          return {
            ...prevState,
            orderDetails: {
              ...prevState.orderDetails,
              items: res.orderItemDistribute.orderDetails
                ? [...res.orderItemDistribute.orderDetails.items]
                : [],
            },
          }
        } else {
          return { ...res.orderItemDistribute.orderDetails }
        }
      })
    }
  }

  const handleReset = async () => {
    const res = await orderItemReset({
      barcode: article.id.toString(),
      hasSuffix: false,
    })
    if (res) {
      if (res.orderItemReset.status === "STOP") {
        enqueueSnackbar("STOP", { variant: "error" })
        return
      }
      setTagRes((prevState: any) => {
        if ("scannedArticle" in prevState) {
          return {
            ...prevState,
            orderDetails: {
              ...prevState.orderDetails,
              items: res.orderItemReset.orderDetails
                ? [...res.orderItemReset.orderDetails.items]
                : [],
            },
          }
        } else {
          return { ...res.orderItemReset.orderDetails }
        }
      })
    }
  }

  const handleCheckboxChange = (event: any) => {
    setCheckedItems((state: any) => {
      // Checkbox war ausgewählt und wird jetzt abgewählt
      if (state.includes(event.target.id)) {
        return state.filter((items: any) => items !== event.target.id)
      }
      // Checkbox wird ausgewählt
      return [...state, event.target.id]
    })
    setRowIsCollapsed((state) => !state)
  }

  return (
    <>
      <TableRow
        key={article.id}
        onClick={() => {
          if (article.isSplitted) {
            return
          }
          setRowIsCollapsed((state) => !state)
        }}
        sx={{ "& td": { border: 0 } }}
      >
        <TableCell style={{ position: "relative" }}>
          <Backdrop
            open={article.isSplitted}
            style={{
              position: "absolute",
              zIndex: 99,
              backgroundColor: "rgba(255, 255, 255, 0.6)",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              variant="overline"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ContentCutIcon /> Splitted!
            </Typography>
          </Backdrop>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Checkbox
              sx={{ pl: 0, ml: -1 }}
              id={article.id.toString()}
              onChange={handleCheckboxChange}
            ></Checkbox>
            <CollapsibleRowArticle article={article} />
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Collapse in={rowIsCollapsed} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="body2" textAlign={textStart}>
                  {t("supplier")}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" textAlign={textStart}>
                  {article.supplier}
                </Typography>
              </Grid>
              {article.tagged && (
                <>
                  <Grid item xs={4}>
                    <Typography variant="body2" textAlign={textStart}>
                      {t("labelled")}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" textAlign={textStart}>
                      {article.timeTagged}/{article.personTagged}
                    </Typography>
                  </Grid>
                </>
              )}
              {article.distributed && (
                <>
                  <Grid item xs={4}>
                    <Typography variant="body2" textAlign={textStart}>
                      {t("distributed")}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" textAlign={textStart}>
                      {article.timeDistributed}/{article.personDistributed}
                    </Typography>
                  </Grid>
                </>
              )}
              {!article.tagged && (
                <Grid item xs={12}>
                  <Button
                    data-cy="buttonLabelling"
                    variant="contained"
                    fullWidth
                    disabled={article.tagged}
                    onClick={handleLabelling}
                  >
                    {t("labelling")}
                  </Button>
                </Grid>
              )}
              {!article.distributed && (
                <Grid item xs={12}>
                  <Button
                    data-cy="buttonDistribute"
                    variant="contained"
                    fullWidth
                    disabled={article.distributed}
                    onClick={handleDistributed}
                  >
                    {t("distribute")}
                  </Button>
                </Grid>
              )}
              {(article.tagged || article.distributed) && (
                <Grid item xs={12}>
                  <Button
                    data-cy="buttonReset"
                    fullWidth
                    variant="contained"
                    disabled={!(article.tagged || article.distributed)}
                    color="error"
                    onClick={handleReset}
                  >
                    {t("reset")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default CollapsibleRow
